import React, { useState ,useEffect} from "react";

import {
Link,
useNavigate
} from "react-router-dom";
import ReactQuill from 'react-quill';

import {HeadBg,MidBg,FootBg,NewMidBg,FstLogo,SiteLogoDark,blogBg,blogImage} from '../ImagesIcons.js';




 import Header from "../includes/Header";
// import Sidebar from "../includes/Sidebar";
// import Navbar from "../includes/Navbar";
// import Pagetitle from "../includes/Pagetitle";


// import Footer from "../includes/Footer";

import {SetNotificationAfterName,Notifyalert,frontUrl , Url,Whybg,Banner,Vision,Visionfirst,Visionsecond,Visionthird,Visionfour, Servicefirst,Servicesecond,Servicethird,Servicefour,Servicefive,Servicesix,Whyfirst,Whysecond,Whythird,Whyfour,Whyfive,Whysix, SiteLogo,redirectfrontPage,Redirectdashboard,Redirectlogin ,containerStart , containerEnd , AkuserId,AKcountsupdated} from '../Frontapi.js';




import Signup from "../auth/Register"


import Dashboard from "../pages/Dashboard"


import SiteHeader from "../includes/SiteHeader";
import SiteFooter from "../includes/SiteFooter";


import Footer from "../includes/Footer";



const Blog = () => {
const navigate = useNavigate();








const [contactname,setcontactName]=useState('');

const [contactemail,setcontactEmail]=useState('');
const [message,setMessage]=useState('');



const [offlemail,setOfflemail]=useState('');
const [offlphone,setOfflphone]=useState('');
const [offlmobile,setOfflmobile]=useState('');

const [offladdress,setOffladdress]=useState('');




const Getsettings = async (e) => {

try{


var postdata={getdata:1};

const response = await fetch(Url+"get-front-settings", {
method: "POST",
headers: {
"Content-Type": "application/json",
} ,
body: JSON.stringify({ postdata }),
}).then((response) => response.json())
.then((data) => {
if (data.success) {



var settingsdata=data.data;
setOfflemail(settingsdata.email );
setOfflphone(settingsdata.phone );
setOfflmobile(settingsdata.mobile );


setOffladdress(settingsdata.address+ ', '+settingsdata.city );





} else{
// ;
}


})



} catch (error) {

console.log(error)

}

}





const SubmitContactus = async (e) => {
e.preventDefault();

try {





var postdata={contactemail:contactemail, contactmessage:message , contactname : contactname };

/***Check Required***/
var required_fields= document.getElementsByClassName('MustContactEnterField')
if(document.getElementsByClassName('notify-input-text')[0]){
document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
}
var error=0;
Array.prototype.forEach.call(required_fields, function(el) {
el.classList.remove('notify-input-missing');
el.classList.add('mb-25');
if(el.value === '' && error == 0){
var inputname = el.name.charAt(0).toUpperCase() + el.name.slice(1);
el.classList.add('notify-input-missing');
el.classList.remove('mb-25');
error=1;



SetNotificationAfterName(el.name, inputname + ' required')
}
});
/***Check Required***/

if(error === 1){
return false
}


const response = await fetch(Url+"create-inquiry", {
method: "POST",
headers: {
"Content-Type": "application/json"
} ,
body:JSON.stringify({postdata})

}).then((response) => response.json())
.then((data) => {
if (data.success) {
Notifyalert('success', data.message)

document.getElementById("ContactForm").reset();
setMessage('')
} else{
// ;
}


})

} catch (error) {
console.log(error)
}
};



const ScrollToSec = async (id) =>
{
var element = document.getElementById(id);
element?.scrollIntoView({
behavior: 'smooth'
});
}


useEffect(() => {

Getsettings();





}, []);


return (

<div id="app">
    <div className=" full-section">
        <div className=" full-section  bg-white">

                <Header pageInfo="Home" />



            
            <SiteHeader />




            <div className="container-fluid p-0">
                <div className="banner-sec">
                    <div className="blog-banner">
                        <img src={blogBg} alt="Blog Banner" className="img-fluid" />
                    </div>
                    <div className="banner-text">
                        Welcome to the Alfakidz Blog
                    </div>
                </div>
            </div>


             <div className="blog-sec">
                <div className="container py-5">
                    <div className="text-center ">
                        <h5 className="amigo-text">Blog</h5>
                        <h1 className="alfa-txt">Welcome to the Alfakidz Blog – Safe & Engaging Kids Social Media </h1>
                        <p>your trusted source for the latest news, trends, and insights on kids' social media and
                            child-friendly social networks. Stay informed about how to create a safe, positive, and
                            enriching online experience for your child..</p>
                    </div>

                    <div className="mt-4 d-flex justify-content-center">
                        <div className="card col-10 ">
                            <div className="row  pt-3 d-flex pb-3">
                                <div className="col-lg-4 col-md-12">
                                    <a href="blogdetail.html" className="thumbnail ">
                                        <img src={blogImage} className="img-fluid" />
                                    </a>
                                </div>
                                <div className="col-lg-8 col-md-12 pt-2">
                                    <div>
                                        <h4 className="alfa-txt">Top 10 Social Networking Apps for Kids in Mumbai - Alfa
                                            Kidz</h4>
                                        <div><i className="fa-solid fa-user fs-6 service-icon"></i><span
                                                className="ms-2">Alfa kidz Admin</span></div>
                                        <div className="pt-1"><i
                                                className="fa-solid fa-calendar-days fs-6 service-icon"></i><span
                                                className="ms-2">January 2025</span></div>
                                        <div><i className="pt-1"><i className="fa-solid fa-comment"></i></i><span
                                                className="ms-2">comments</span></div>
                                        <p>Alfakidz is Mumbai's premier child-friendly social network, designed to
                                            provide a safe and positive online environment for kids. With features like
                                            parental controls, age-appropriate content, and a focus on social-emotional
                                            learning,</p>
                                        <Link className="blog-txt" to={frontUrl+'blog/top-10-social-networking-apps-for-kids-in-mumbai'}>Know More </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 







        </div>
   <SiteFooter />
    </div>

</div>

);
};
export default Blog;