import React, { useState ,useEffect} from "react";

import {
Link,
useNavigate
} from "react-router-dom";
import ReactQuill from 'react-quill';

import {HeadBg,MidBg,FootBg,NewMidBg,FstLogo,SiteLogoDark,blogBg,blogImage} from '../ImagesIcons.js';




  import Header from "../includes/Header";
// import Sidebar from "../includes/Sidebar";
// import Navbar from "../includes/Navbar";
// import Pagetitle from "../includes/Pagetitle";


// import Footer from "../includes/Footer";

import {SetNotificationAfterName,Notifyalert,frontUrl , Url,Whybg,Banner,Vision,Visionfirst,Visionsecond,Visionthird,Visionfour, Servicefirst,Servicesecond,Servicethird,Servicefour,Servicefive,Servicesix,Whyfirst,Whysecond,Whythird,Whyfour,Whyfive,Whysix, SiteLogo,redirectfrontPage,Redirectdashboard,Redirectlogin ,containerStart , containerEnd , AkuserId,AKcountsupdated} from '../Frontapi.js';




import Signup from "../auth/Register"


import Dashboard from "../pages/Dashboard"


import SiteHeader from "../includes/SiteHeader";
import SiteFooter from "../includes/SiteFooter";


import Footer from "../includes/Footer";



const Blog = () => {
const navigate = useNavigate();








const [contactname,setcontactName]=useState('');

const [contactemail,setcontactEmail]=useState('');
const [message,setMessage]=useState('');



const [offlemail,setOfflemail]=useState('');
const [offlphone,setOfflphone]=useState('');
const [offlmobile,setOfflmobile]=useState('');

const [offladdress,setOffladdress]=useState('');




const Getsettings = async (e) => {

try{


var postdata={getdata:1};

const response = await fetch(Url+"get-front-settings", {
method: "POST",
headers: {
"Content-Type": "application/json",
} ,
body: JSON.stringify({ postdata }),
}).then((response) => response.json())
.then((data) => {
if (data.success) {



var settingsdata=data.data;
setOfflemail(settingsdata.email );
setOfflphone(settingsdata.phone );
setOfflmobile(settingsdata.mobile );


setOffladdress(settingsdata.address+ ', '+settingsdata.city );





} else{
// ;
}


})



} catch (error) {

console.log(error)

}

}





const SubmitContactus = async (e) => {
e.preventDefault();

try {





var postdata={contactemail:contactemail, contactmessage:message , contactname : contactname };

/***Check Required***/
var required_fields= document.getElementsByClassName('MustContactEnterField')
if(document.getElementsByClassName('notify-input-text')[0]){
document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
}
var error=0;
Array.prototype.forEach.call(required_fields, function(el) {
el.classList.remove('notify-input-missing');
el.classList.add('mb-25');
if(el.value === '' && error == 0){
var inputname = el.name.charAt(0).toUpperCase() + el.name.slice(1);
el.classList.add('notify-input-missing');
el.classList.remove('mb-25');
error=1;



SetNotificationAfterName(el.name, inputname + ' required')
}
});
/***Check Required***/

if(error === 1){
return false
}


const response = await fetch(Url+"create-inquiry", {
method: "POST",
headers: {
"Content-Type": "application/json"
} ,
body:JSON.stringify({postdata})

}).then((response) => response.json())
.then((data) => {
if (data.success) {
Notifyalert('success', data.message)

document.getElementById("ContactForm").reset();
setMessage('')
} else{
// ;
}


})

} catch (error) {
console.log(error)
}
};



const ScrollToSec = async (id) =>
{
var element = document.getElementById(id);
element?.scrollIntoView({
behavior: 'smooth'
});
}


useEffect(() => {

Getsettings();





}, []);


return (

<div id="app">
    <div className=" full-section">
        <div className=" full-section  bg-white">

            
              <Header pageInfo="Blogdetail" />



            <SiteHeader />



        
            <div className="container py-3 py-md-4">
                <section className="detail-content">
                    <div className="deatil-heading">
                        <h1>Top 10 Social Networking Apps for Kids in Mumbai - Alfa Kidz</h1>
                        <hr/>
                        <div className="d-flex flex-wrap justify-content-start align-items-center">
                            <div className="me-3"><i className="fa-solid fa-user fs-6 service-icon"></i><span
                                    className="ms-2">Alfa kidz Admin</span></div>
                            <div className="me-3"><i className="fa-solid fa-calendar-days fs-6 service-icon"></i><span
                                    className="ms-2">January
                                    2025</span></div>
                           
                        </div>
                    </div>

                    <div className="d-flex justify-content-center col-12 pt-3">
                        <div className=" col-8">
                                <img className="img-fluid w-100" src={blogImage} />
                        </div>
                    </div>
                  <p>  In today's digital age, kids are increasingly drawn to social media. However, not all platforms are safe or suitable for young users. As a parent in Mumbai, finding a child-friendly social network that prioritizes online safety, digital wellness, and creative growth is essential.</p>


<p>To help you make an informed choice, we've curated a list of the top 10 social networking apps for kids in Mumbai, with Alfakidz leading the pack!</p>




                    <h4>Why Alfakidz Stands Out</h4>
                    <p>Alfakidz is Mumbai's premier child-friendly social network, designed to provide a <b>safe and positive online environment</b> for kids. With features like <b>parental controls, age-appropriate content</b> and a focus on <b>social-emotional learning</b> Alfakidz ensures that your child's online experience is both fun and enriching.</p>

                    <h4>Key Features</h4>
                    <p><strong>Online Safety:</strong> Advanced privacy settings and monitoring tools to keep kids safe.</p>
                    <p><strong>Creative Expression:</strong> A platform for kids to showcase their talents and creativity.</p>
                    <p><strong>Educational Networking:</strong> Combines social interaction with learning opportunities.</p>
                    <p><strong>Positive Community:</strong> Encourages kindness, respect, and inclusivity.</p>

                    <h4>Why Mumbai Parents Love Alfakidz</h4>
                    <p>Alfakidz is trusted by parents across Mumbai for its commitment to <b>digital wellness</b> and <b>child development</b>. It's more than just a social network - it's a platform where kids can learn, grow, and thrive.</p>

                    <h4>Comparison with Other Platforms</h4>
                    <p><strong>Messenger Kids:</strong> A popular choice for younger kids, Messenger Kids offers a controlled environment for messaging and video calls. However, it lacks the <b>creative and educational features</b> that Alfakidz provides.</p>
                    <p><strong>Kuddle:</strong> Kuddle is a photo-sharing app designed for kids, with a focus on <b>anti-cyberbullying</b> measures. While it promotes positivity, it doesn't offer the same level of <b>social-emotional learning</b> or <b>talent showcase</b> opportunities as Alfakidz.</p>
                    <p><strong>PopJam:</strong> PopJam is a creative platform where kids can share drawings, play games, and interact with peers. However, it lacks the <b>parental control features</b> and <b>educational networking</b> that make Alfakidz a standout choice.</p>
                    <p><strong>Everloop:</strong> Everloop is a social network designed for kids under 13, with a focus on online safety. While it offers a safe space for interaction, it doesn't provide the same creative and educational tools as Alfakidz.</p>
                    <p><strong>Kidzworld:</strong> Kidzworld is a social platform that combines games, forums, and educational content. However, it's more focused on entertainment than social-emotional learning or talent development.</p>
                    <p><strong>Yoursphere:</strong> Yoursphere is a kid-friendly social network that emphasizes online safety and positive interactions. While it's a good option, it doesn't offer the same level of creative expression or <b>community engagement</b> as Alfakidz.</p>
                    <p><strong>Grom Social:</strong> Grom Social is designed for kids to connect with friends and family in a safe environment. However, it lacks the <b>educational networking</b> and talent showcase features that make Alfakidz unique.</p>
                    <p><strong>Kudos:</strong> Kudos is a browser-based social network for kids, with a focus on online safety and parental controls. While it's a secure option, it doesn't provide the same creative and learning opportunities as Alfakidz.</p>
                    <p><strong>Zigazoo:</strong> Zigazoo is a video-sharing app for kids, with a focus on creativity and fun challenges. However, it doesn't offer the same social-emotional learning or <b>educational networking</b> features as Alfakidz.</p>

                    <h4>Why Alfakidz is the Best Choice for Mumbai Kids</h4>
                    <p>While there are many social networking apps for kids, Alfakidz stands out as the #1 choice for parents in Mumbai. Here's why:</p>
                    <ul>
                        <li><strong>Safety First:</strong> Alfakidz prioritizes online safety with robust parental controls and monitoring tools.</li>
                        <li><strong>Creative Growth:</strong> Kids can showcase their talents and explore their creativity in a supportive environment.</li>
                        <li><strong>Educational Value:</strong> Alfakidz combines social interaction with learning opportunities, making it a well-rounded platform.</li>
                        <li><strong>Positive Community:</strong> Alfakidz fosters a <b>kind and inclusive online community</b> where kids can thrive.</li>
                    </ul>

                    <h4>Conclusion</h4>
                    <p>Choosing the right social networking app for your child is a crucial decision in today's digital world. While there are several options available, Alfakidz emerges as the clear winner for parents in Mumbai who value online safety, creative expression, and educational growth.</p>
                    <p>With its unique blend of <b>child-friendly features, social-emotional learning tools</b> and a <b>positive online environment</b>, Alfakidz is more than just a social network - it's a platform that helps kids grow into confident, creative, and responsible digital citizens.</p>
                </section>
            </div>

 
 







        </div>
   <SiteFooter />
    </div>

</div>

);
};
export default Blog;